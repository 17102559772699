@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  font-family: 'Karla', sans-serif;
}
html, body {
  overscroll-behavior-x: none;
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --novel-highlight-default: #ffffff;
    --novel-highlight-purple: #f6f3f8;
    --novel-highlight-red: #fdebeb;
    --novel-highlight-yellow: #fbf4a2;
    --novel-highlight-blue: #c1ecf9;
    --novel-highlight-green: #acf79f;
    --novel-highlight-orange: #faebdd;
    --novel-highlight-pink: #faf1f5;
    --novel-highlight-gray: #f1f1ef;
    --tw-ring-color: red !important;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;

    --novel-highlight-default: #000000;
    --novel-highlight-purple: #3f2c4b;
    --novel-highlight-red: #5c1a1a;
    --novel-highlight-yellow: #5c4b1a;
    --novel-highlight-blue: #1a3d5c;
    --novel-highlight-green: #1a5c20;
    --novel-highlight-orange: #5c3a1a;
    --novel-highlight-pink: #5c1a3a;
    --novel-highlight-gray: #3a3a3a;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}


pre {
  background: #0d0d0d;
  border-radius: 0.5rem;
  color: #fff;
  font-family: "JetBrainsMono", monospace;
  padding: 0.75rem 1rem;

  code {
    background: none;
    color: inherit;
    font-size: 0.8rem;
    padding: 0;
  }

  .hljs-comment,
  .hljs-quote {
    color: #616161;
  }

  .hljs-variable,
  .hljs-template-variable,
  .hljs-attribute,
  .hljs-tag,
  .hljs-name,
  .hljs-regexp,
  .hljs-link,
  .hljs-name,
  .hljs-selector-id,
  .hljs-selector-class {
    color: #f98181;
  }

  .hljs-number,
  .hljs-meta,
  .hljs-built_in,
  .hljs-builtin-name,
  .hljs-literal,
  .hljs-type,
  .hljs-params {
    color: #fbbc88;
  }

  .hljs-string,
  .hljs-symbol,
  .hljs-bullet {
    color: #b9f18d;
  }

  .hljs-title,
  .hljs-section {
    color: #faf594;
  }

  .hljs-keyword,
  .hljs-selector-tag {
    color: #70cff8;
  }

  .hljs-emphasis {
    font-style: italic;
  }

  .hljs-strong {
    font-weight: 700;
  }
}
.relative.min-h-\[500px\].w-full.max-w-screen-lg.border-muted.bg-background.sm\:mb-\[calc\(20vh\)\].sm\:rounded-lg.sm\:border.sm\:shadow-lg {
  box-shadow: none !important;
  border: none !important;
}
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700&display=swap");

:root {
  /* fonts */
  --body-small-medium: Inter;
  --font-Karla: Karla;
  --font-nunito: Nunito;

  /* font sizes */
  --body-small-medium-size: 14px;
  --body-normal-medium-size: 16px;
  --heading-h5-size: 22px;
  --body-large-regular-size: 18px;
  --font-size-13xl: 32px;
  --font-size-7xl: 26px;
  --font-size-lgi: 19px;
  --heading-h3-size: 40px;
  --font-size-5xl: 24px;
  --heading-h6-size: 20px;
  --heading-h1-size: 56px;
  --font-size-26xl: 45px;
  --font-size-15xl: 34px;
  --font-size-xs: 12px;
  --font-size-3xs: 10px;
  --font-size-lgi-5: 19.5px;
  --body-xs-medium-size: 13px;

  /* Colors */
  --color-midnightblue-100: #2b0771;
  --color-midnightblue-200: #29076c;
  --color-midnightblue-300: #260666;
  --color-midnightblue-400: #260066;
  --color-dimgray: #564e66;
  --color-steelblue: #816fa3;
  --text-white: #fff;
  --color-gainsboro-100: #e6e6e6;
  --color-ghostwhite: #f3f0fa;
  --color-mediumblue-100: rgba(77, 13, 204, 0.1);
  --color-gray-100: rgba(255, 255, 255, 0.2);
  --color-gray: rgba(255, 255, 255, 0.1);
  --color-black: #000;
  --color-whitesmoke-100: #f5f5f5;
  --color-whitesmoke-200: #f0f0f0;
  --color-whitesmoke-300: #eee;
  --color-lavender: #ddd3f0;
  --color-plum: #9480bc;
  --color-darkgray: #a1a0a3;
  --color-darkslateblue: rgba(122, 102, 163, 0.09);

  /* Gaps */
  --gap-21xl: 40px;
  --gap-xl: 20px;
  --gap-base: 16px;
  --gap-xs: 12px;
  --gap-9xl: 28px;
  --gap-30xl: 49px;
  --gap-5xl: 24px;
  --gap-11xs: 2px;
  --gap-37xl: 56px;
  --gap-5xs: 8px;
  --gap-2xs-8: 10.8px;
  --gap-13xl: 32px;
  --gap-mid: 17px;
  --gap-11xl: 30px;
  --gap-7xs: 6px;
  --gap-mini: 15px;
  --gap-8xs-8: 4.8px;
  --gap-2xs: 11px;
  --gap-smi: 13px;
  --gap-8xs-4: 4.4px;
  --gap-332xl: 351px;
  --gap-3xs-9: 9.9px;
  --gap-29xl: 48px;
  --gap-101xl: 120px;
  --gap-41xl: 60px;

  /* Paddings */
  --padding-29xl: 48px;
  --padding-xl: 20px;
  --padding-5xl: 24px;
  --padding-47xl: 66px;
  --padding-79xl: 98px;
  --padding-111xl: 130px;
  --padding-12xl: 31px;
  --padding-6xs: 7px;
  --padding-77xl: 96px;
  --padding-21xl: 40px;
  --padding-63xl: 82px;
  --padding-43xl: 62px;
  --padding-15xl: 34px;
  --padding-11xl: 30px;
  --padding-smi-2: 12.2px;
  --padding-10xs: 3px;
  --padding-9xs: 4px;
  --padding-6xl: 25px;
  --padding-12xs: 1px;
  --padding-base: 16px;
  --padding-8xs: 5px;
  --padding-49xl: 68px;
  --padding-lg: 18px;
  --padding-sm: 14px;
  --padding-smi: 13px;
  --padding-mid: 17px;
  --padding-11xs: 2px;
  --padding-5xs: 8px;
  --padding-8xs-1: 4.1px;
  --padding-mini: 15px;
  --padding-23xl: 42px;
  --padding-xs: 12px;
  --padding-61xl: 80px;
  --padding-3xl: 22px;
  --padding-113xl: 132px;
  --padding-10xs-5: 2.5px;
  --padding-11xs-5: 1.5px;
  --padding-7xs-5: 5.5px;
  --padding-7xs-6: 5.6px;
  --padding-19xl: 38px;
  --padding-157xl: 176px;
  --padding-95xl: 114px;
  --padding-8xl: 27px;
  --padding-14xl: 33px;
  --padding-108xl: 127px;
  --padding-64xl: 83px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-xs: 12px;
  --br-12xs-6: 0.6px;
  --br-9xs: 4px;
  --br-base: 16px;
  --br-8xs-2: 4.2px;
  --br-80xl: 99px;
  --br-3xs: 10px;
  --tw-ring-color: rgb(0, 255, 72);
}
.recharts-tooltip-wrapper.recharts-tooltip-wrapper-left.recharts-tooltip-wrapper-top {
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.recharts-tooltip-wrapper.recharts-tooltip-wrapper-right.recharts-tooltip-wrapper-top {
  background-color: white;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.ProseMirror {
  @apply p-12 px-8 sm:px-12;
}

.ProseMirror .is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: hsl(var(--muted-foreground));
  pointer-events: none;
  height: 0;
}
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: hsl(var(--muted-foreground));
  pointer-events: none;
  height: 0;
}

/* Custom image styles */
.ProseMirror img {
  transition: filter 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    filter: brightness(90%);
  }

  &.ProseMirror-selectednode {
    outline: 3px solid #5abbf7;
    filter: brightness(90%);
  }
}

.img-placeholder {
  position: relative;

  &:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 3px solid var(--novel-stone-200);
    border-top-color: var(--novel-stone-800);
    animation: spinning 0.6s linear infinite;
  }
}

@keyframes spinning {
  to {
    transform: rotate(360deg);
  }
}

/* Custom TODO list checkboxes – shoutout to this awesome tutorial: https://moderncss.dev/pure-css-custom-checkbox-style/ */
ul[data-type="taskList"] li > label {
  margin-right: 0.2rem;
  user-select: none;
}

@media screen and (max-width: 768px) {
  ul[data-type="taskList"] li > label {
    margin-right: 0.5rem;
  }
}

ul[data-type="taskList"] li > label input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: hsl(var(--background));
  margin: 0;
  cursor: pointer;
  width: 1.2em;
  height: 1.2em;
  position: relative;
  top: 5px;
  border: 2px solid hsl(var(--border));
  margin-right: 0.3rem;
  display: grid;
  place-content: center;

  &:hover {
    background-color: hsl(var(--accent));
  }

  &:active {
    background-color: hsl(var(--accent));
  }

  &::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em;
    transform-origin: center;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  &:checked::before {
    transform: scale(1);
  }
}

ul[data-type="taskList"] li[data-checked="true"] > div > p {
  color: var(--muted-foreground);
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

/* Overwrite tippy-box original max-width */
.tippy-box {
  max-width: 400px !important;
}

.ProseMirror:not(.dragging) .ProseMirror-selectednode {
  outline: none !important;
  background-color: var(--novel-highlight-blue);
  transition: background-color 0.2s;
  box-shadow: none;
}

.drag-handle {
  position: fixed;
  opacity: 1;
  transition: opacity ease-in 0.2s;
  border-radius: 0.25rem;

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(0, 0, 0, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem);
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.5rem;
  z-index: 50;
  cursor: grab;

  &:hover {
    background-color: var(--novel-stone-100);
    transition: background-color 0.2s;
  }

  &:active {
    background-color: var(--novel-stone-200);
    transition: background-color 0.2s;
    cursor: grabbing;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  @media screen and (max-width: 600px) {
    display: none;
    pointer-events: none;
  }
}

.dark .drag-handle {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(255, 255, 255, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
}

/* Custom Youtube Video CSS */
iframe {
  border: 8px solid #ffd00027;
  border-radius: 4px;
  min-width: 200px;
  min-height: 200px;
  display: block;
  outline: 0px solid transparent;
}

div[data-youtube-video] > iframe {
  cursor: move;
  aspect-ratio: 16 / 9;
  width: 100%;
}

.ProseMirror-selectednode iframe {
  transition: outline 0.15s;
  outline: 6px solid #fbbf24;
}

@media only screen and (max-width: 480px) {
  div[data-youtube-video] > iframe {
    max-height: 50px;
  }
}

@media only screen and (max-width: 720px) {
  div[data-youtube-video] > iframe {
    max-height: 100px;
  }
}

/* CSS for bold coloring and highlighting issue*/
span[style] > strong {
  color: inherit;
}

mark[style] > strong {
  color: inherit;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  /* top: 0;
  right: 10;
  position: absolute;
  width: 20px;
  height: 40px; */
  /* background-repeat: no-repeat; */
  /* background-origin: content-box; */
  /* box-sizing: border-box; */
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+'); */
  /* background-position: bottom right; */
  /* padding: 0 3px 3px 0; */
  /* background-color: rgb(75 85 99 )/5; */
  /* z-index: 1000; */
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  /* top: 50%; */
  /* margin-top: -10px; */
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  /* transform: rotate(315deg); */
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
.rc-md-editor{
  width: 700px;
}
.rc-md-editor .editor-container{
  flex: none !important;
  display: block !important;
}
/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Set scrollbar width */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}
/* Custom Scrollbar for both vertical and horizontal scrolling */
::-webkit-scrollbar {
  width: 6px; /* Vertical scrollbar width */
  height: 6px; /* Horizontal scrollbar height */
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 4px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}
#shadow-x {
  box-shadow: none !important;
}
.myTooltipClass{
  min-width: 400px !important;
}
.introjs-button {
  box-sizing: content-box;
  position: relative;
  overflow: visible;
  padding: .5rem 1rem;
  border: none !important;
  text-decoration: none;
  text-shadow: none !important;
  font-size: 0.875rem !important;
  color: white  !important;
  white-space: nowrap;
  cursor: pointer;
  outline: 0;
  border-radius: .4rem !important;
  zoom: 1;
  display: inline;
  background-color: rgb(12 163 127) !important;
}
.introjs-button:focus {
  outline: 0;
  text-decoration: none;
  background-color: rgb(12 163 127) !important;
  box-shadow: none !important;
  border: none !important;
  color: white  !important;
}
.introjs-bullets{
  display: none !important;
}
.introjs-tooltipbuttons{
  display: none !important;
}
.introjs-tooltiptext {
  margin-top: -45px !important
}